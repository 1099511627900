<template>
  <div class="step-progress-container">
    <ul class="step-progress-bar">
      <li :class="isActive(i)" v-for="i in length" :key="i"> </li>
    </ul>
  </div>
</template>
<script>
export default {
  /**
    How to Use.
    1. Import this vue file to the desired vue file
    2. setup component
    3. 

    length: number of steps
    finished: number of finished steps
    returned: toggle final step color
  **/
  props:['length','finished','returned'],
  data () {
    return {
    }
  },
  methods:{
    isActive(index){
      console.info('isActive '+this.finished,index)
      if(index<=0){
        return ""
      }
      
      if((this.finished==this.length)&&(index==this.finished)&&this.returned){
        console.log('returned ',this.returned)
        return "ng"
      }
      if(index<=this.finished){
        return "active"
      }
      return ""
    }
  }
}
</script>
<style scoped>
.step-progress-container{
     width: 35%;
    position: absolute;
    z-index: 1;
    margin-top: 20px;
}
.step-progress-bar{
  list-style-type: none;
  counter-reset: step;
}
.step-progress-bar li{
  float: left;
  width: 14.28%;
  position: relative;
  text-align: center;
}
.step-progress-bar li:before{
  content: "😶";
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.step-progress-bar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.step-progress-bar li.active:before{
  content: "😀";
 border-color: #3aac5d;
 background: #3aac5d;
 color: white
}
.step-progress-bar li.active:after{
 background: #3aac5d;
}
.step-progress-bar li.active - li:after{
 background: #3aac5d;
}
.step-progress-bar li.active - li:before{
  border-color: #3aac5d;
  background: #3aac5d;
  color: white
}
.step-progress-bar li.ng:before{
  content: "🙂";
 border-color: #FFA500;
 background: #FFA500;
 color: white
}
.step-progress-bar li.ng:after{
 background: #FFA500;
}
.step-progress-bar li.ng - li:after{
 background: #FFA500;
}
.step-progress-bar li.ng - li:before{
  border-color: #FFA500;
  background: #FFA500;
  color: white
}
.step-progress-bar li:first-child:after{
 content: none;
}
</style>